import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import GlobalStyle from "../helpers/styles/globalStyle";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import { HelpForHouseholds } from "../components/HelpForHouseholds";

export const IndexPageTemplate = ({ data }) => {
  const {
    metadataTitle,
    metadataDescription,
    Hero: hero,
    image,
    supportSchemes,
  } = data;

  return (
    <div>
      <Helmet>
        <title>{metadataTitle}</title>
        <meta name="description" content={metadataDescription} />
        <meta
          name="google-site-verification"
          content="Hyxc-eJ8zjz9K5Aw5SDs_ylxAj2gLV6ss4e7GcAvILM"
        />
      </Helmet>
      <Hero info={hero} image={image} />
      {/* <InlineBanner>{inlineBanner}</InlineBanner> */}
      <HelpForHouseholds info={supportSchemes} />
      <Footer />
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  metadataTitle: PropTypes.string,
  metadataDescription: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  inlineBanner: PropTypes.string,
  description: PropTypes.string,
  mainpitch: PropTypes.object,
  mainpitchPanel: PropTypes.object,
  mainpitchPAYGList: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  boostAppList: PropTypes.object,
  mainpitchBenefitsList: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <GlobalStyle />
      <IndexPageTemplate data={frontmatter} />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        metadataTitle
        metadataDescription
        inlineBanner
        Hero {
          heading
          description
          button
        }
        strugglingToPay {
          heading
          description
          button
        }
        winterCalcBanner {
          heading
          subHeading
          button {
            link
            text
          }
          finePrint
        }
        supportSchemes {
          title
          subTitle
          sections {
            subTitle
            body
            active
            activeFrom
            linkTitle
            link
          }
        }
        image {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mainpitchPAYGList {
          title
          subtitle
          description
          image {
            publicURL
            name
            childImageSharp {
              fluid(quality: 90, maxWidth: 1240) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          blurbs {
            image {
              publicURL
              name
            }
            text
          }
          PAYG_traditional_title
          PAYG_traditional_description
        }
        boostAppList {
          downloadText
          appsList {
            heading
            image {
              publicURL
              name
            }
            link
          }
        }
        mainpitchPanel {
          title
          description
          image {
            publicURL
            name
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        mainpitchBenefitsList {
          title
          blurbs {
            image {
              publicURL
              name
            }
            heading
            description
          }
        }
      }
    }
  }
`;
