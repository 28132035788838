import React from "react";
import PropTypes from "prop-types";
import BackgroundImage from "gatsby-background-image";
import { ButtonWhiteBlue } from "../components/ButtonLinks";
import styled from "styled-components";
import colors from "../helpers/styles/colors";
import typography from "../helpers/styles/typography";
import spacing from "../helpers/styles/spacing";
import breakpoints from "../helpers/styles/breakpoints";

export const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const HeroContainerBackground = styled.div`
  min-height: 600px;

  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    width: 100%;
    height: 100%;
    // background: linear-gradient(180deg, rgb(255, 198, 3) 66%, rgb(16, 120, 150) 76%);
  }

  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: -4px;
    width: 102%;
    height: 100%;
    background: url("/img/yellow-background-hero.svg") no-repeat bottom center;
    background-size: cover;
    background-position: center bottom;
  }
`;

export const HeroContainerWrapper = styled.div`
  width: 100%;
  position: relative;
  margin: 0 auto;
  z-index: 3;
  margin-top: ${spacing.gutter20};
  @media ${breakpoints.tablet} {
    max-width: 1100px;
    min-width: 1020px;
  }
`;

export const HeroContainerHeader = styled.div`
  @media ${breakpoints.tablet} {
    position: absolute;
    left: 25px;
    max-width: 500px;
    text-align: left;
    z-index: 5;
  }
`;

export const HeroTitleContainer = styled.div`
  max-width: 430px;
  padding: 0 ${spacing.gutter20};
  @media ${breakpoints.tablet} {
    padding: 0;
    margin: 0 auto;
  }
`;

export const HeroTitle = styled.h2`
  font: ${typography.headlineMobile};
  color: ${colors.black};
  margin-bottom: 10px;
  @media ${breakpoints.tablet} {
    font: ${typography.h1};
    margin-top: 100px;
  }
`;

export const HeroDescription = styled.p`
  margin-top: ${spacing.gutter20};
  font: ${typography.subtitleDesktop};
  font-weight: 300px;
  padding-bottom: 20px;
  padding-top: 0px;
`;

export const HeroGetQuote = styled.div`
  display: flex;
  margin: ${spacing.gutter20} 0;
  padding: 0 ${spacing.gutter25};

  @media ${breakpoints.tablet} {
    justify-content: start;
    padding: 0;
    margin-left: -5px;
  }

  button {
    font-size: ${typography.fontSizeSmall};
    padding: ${spacing.gutter10} ${spacing.gutter20};
    cursor: pointer;
    @media ${breakpoints.tablet} {
      padding: ${spacing.gutter10} ${spacing.gutter40};
    }
  }

  input {
    padding: 0px 0px 0px ${spacing.gutter20};
    margin: 0;
    font-size: ${typography.fontSizeSmall};
    width: 170px;
  }
`;

export const HeroContainerFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  top: ${spacing.gutter20};
  right: 0;

  .flex1 {
    z-index: 3;
    right: -150px;
    @media ${breakpoints.mobileM} {
      right: -130px;
    }
    @media ${breakpoints.mobileL} {
      right: -85px;
    }
  }
  .flex2 {
    right: -30px;
  }
  .flex1,
  .flex2 {
    position: relative;
    align-self: center;
    @media ${breakpoints.tablet} {
      align-self: baseline;
    }
  }
  @media ${breakpoints.tablet} {
    top: ${spacing.gutter80};
  }
`;

export const ImgSection = styled(BackgroundImage)`
  background-size: cover;
  width: 360px;
  height: 280px;
  margin: 0 auto;
  mask-image: url("/img/header_shape.svg");
  mask-repeat: no-repeat;
  mask-position: top;
  mask-size: 360px 280px;
  top: 20px;
  left: -9px;

  &:before {
    transform: rotate(5deg);
  }

  @media (min-width: 550px) {
    width: 470px;
    height: 350px;
    mask-size: 470px 350px;
    top: 23px;
    left: -7px;
  }
  @media ${breakpoints.tablet} {
    width: 535px;
    height: 400px;
    mask-size: 535px 400px;
  }
`;

export const ImgSectionShade = styled.div`
  background: url("/img/hero_image_shade.svg") no-repeat center;
  background-size: cover;
  width: 406px;
  height: 315px;
  @media (min-width: 550px) {
    width: 508px;
    height: 393px;
  }
  @media ${breakpoints.tablet} {
    width: 580px;
    height: 450px;
  }
`;

const Hero = ({ info, image }) => {
  const imageData = image?.childImageSharp?.fluid;

  return (
    <HeroContainer>
      <HeroContainerBackground>
        <HeroContainerWrapper>
          <HeroContainerHeader>
            <HeroTitleContainer>
              <HeroTitle>{info.heading}</HeroTitle>
              <HeroDescription>{info.description}</HeroDescription>
            </HeroTitleContainer>
          </HeroContainerHeader>
          <HeroContainerFooter>
            <div className="flex2">
              <ImgSectionShade>
                <ImgSection Tag="div" className={"gatsby-bg"} fluid={imageData}></ImgSection>
              </ImgSectionShade>
            </div>
          </HeroContainerFooter>
        </HeroContainerWrapper>
      </HeroContainerBackground>
    </HeroContainer>
  );
};

Hero.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};
export default Hero;
